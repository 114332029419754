import React from "react";
import { TideItemForNavigationSection } from "../../types";

interface FooterContactProps {
  contactNavigationSection?: TideItemForNavigationSection;
}

const FooterContact: React.FC<FooterContactProps> = ({ contactNavigationSection }) => {
  if (!contactNavigationSection?.content?.general?.title) {
    return null;
  }

  const contactContent = contactNavigationSection.content.general;
  return (
    <>
      <h4 className="footer__heading">{contactContent.title}</h4>
      {contactContent.text && <div dangerouslySetInnerHTML={{ __html: contactContent.text }}></div>}
    </>
  );
};

export default FooterContact;
