import React from "react";
import Icon from "../../../icon";

interface NewsletterVoetbalreizenFormProps {}

const NewsletterVoetbalreizenForm: React.FC<NewsletterVoetbalreizenFormProps> = () => {
  return (
    <form method="POST" id="zcampaignOptinForm" className="form form--newsletter" action="https://maillist-manage.eu/weboptin.zc" target="_zcSignup">
      <div className="form__row">
        <div className="form__group">
          {/* <input type="email" className="form__input" placeholder="Jouw e-mailadres" required /> */}
          <input type="email" className="form__input" placeholder="Email" name="CONTACT_EMAIL" id="EMBED_FORM_EMAIL_LABEL" required />
        </div>
      </div>
      <div className="form__row">
        <div className="form__group">
          {/* <input type="text" className="form__input" placeholder="Voornaam" required /> */}
          <input type="text" className="form__input" placeholder="Voornaam" name="FIRSTNAME" id="FIRSTNAME" required />
        </div>
      </div>
      <div className="form__row">
        <div className="form__group">
          {/* <input type="text" className="form__input" placeholder="Naam" required /> */}
          <input type="text" className="form__input" placeholder="Achternaam" name="LASTNAME" id="LASTNAME" required />
        </div>
      </div>
      <div className="form__row">
        <div className="form__group">
          <button type="submit" className="cta">
            <Icon name="send" />
            Aanmelden
          </button>
        </div>
      </div>
      <input type="hidden" id="fieldBorder" value="" />
      <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW" />
      <input type="hidden" name="viewFrom" id="viewFrom" value="URL_ACTION" />
      <input type="hidden" id="submitType" name="submitType" value="optinCustomView" />
      <input type="hidden" id="lD" name="lD" value="1a003a430eb577" />
      <input type="hidden" name="emailReportId" id="emailReportId" value="" />
      <input type="hidden" name="zx" id="cmpZuid" value="14acf6bb72" />
      <input type="hidden" name="zcvers" value="2.0" />
      <input type="hidden" name="oldListIds" id="allCheckedListIds" value="" />
      <input type="hidden" id="mode" name="mode" value="OptinCreateView" />
      <input type="hidden" id="zcld" name="zcld" value="1a003a430eb577" />
      <input type="hidden" id="zctd" name="zctd" value="" />
      <input type="hidden" id="document_domain" value="" />
      <input type="hidden" id="zc_Url" value="voet.maillist-manage.eu" />
      <input type="hidden" id="new_optin_response_in" value="0" />
      <input type="hidden" id="duplicate_optin_response_in" value="0" />
      <input type="hidden" id="zc_formIx" name="zc_formIx" value="3zecae99c2f3a64749b494e34a45d9dccbc2f16678a42e137f9705350d34c5d4f6" />
      <input type="hidden" id="scriptless" name="scriptless" value="yes" />
    </form>
  );
};

export default NewsletterVoetbalreizenForm;
