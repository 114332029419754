import React from "react";
import { TideItemForNavigationSection } from "../../types";
import NewsletterForm from "../forms/newsletter";

interface FooterNewsletterProps {
  newsletterNavigationSection?: TideItemForNavigationSection;
}

const FooterNewsletter: React.FC<FooterNewsletterProps> = ({ newsletterNavigationSection }) => {
  if (!newsletterNavigationSection?.content?.general) {
    return null;
  }
  const newsletterContent = newsletterNavigationSection.content.general;

  return (
    <>
      <h4 className="footer__heading">{newsletterContent.title}</h4>
      {newsletterContent.text && <div dangerouslySetInnerHTML={{ __html: newsletterContent.text }}></div>}
      <div className="newsletter">
        <NewsletterForm></NewsletterForm>
      </div>
    </>
  );
};

export default FooterNewsletter;
