import React, { useContext } from "react";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { TideItemForNavigationItem } from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import { generateRootPath } from "../../utils/path";
import Link from "../link";

interface FooterSocialsProps {}

const FooterSocials: React.FC<FooterSocialsProps> = () => {
  const { socialNavigation } = useContext<ComponentContextType>(ComponentContext);
  if (!socialNavigation?.childItems) {
    return null;
  }

  const socials = getChildItemsWithTypename("TideItemForNavigationItem", socialNavigation) as TideItemForNavigationItem[];

  return (
    <div className="socials-anchors">
      {socials?.map((item) => (
        <div key={item.id}>
          <Link
            path={generateRootPath(parse(item.content?.general?.path))}
            url={parse(item.content?.general?.url)}
            title={parse(item.content?.general?.title)}
            className="socials-anchors__anchor"
          >
            <i className={parse(item.content?.general?.iconFontAwesome)} aria-hidden="true"></i>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FooterSocials;
