import React from "react";
import { TideItemForNavigationSection } from "../../types";
import { getChildItemsWithTypename } from "../../utils/component";
import FooterLinkGroup from "./footer-link-group";

interface FooterLinksProps {
  linksNavigationSection?: TideItemForNavigationSection;
}

const FooterLinks: React.FC<FooterLinksProps> = ({ linksNavigationSection }) => {
  if (!linksNavigationSection?.content?.general) {
    return null;
  }

  const linkGroups = getChildItemsWithTypename("TideItemForNavigationSection", linksNavigationSection) as TideItemForNavigationSection[];

  return (
    <>
      <h4 className="footer__heading">{linksNavigationSection.content.general.title}</h4>
      {linkGroups.map((item) => (
        <FooterLinkGroup linkGroupNavigationSection={item} key={item.id} />
      ))}
    </>
  );
};

export default FooterLinks;
