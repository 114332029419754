import React, { useContext } from "react";
import GlobalContext from "../../contexts/global-provider";

interface NotificationBarProps {
  text: string;
  icon?: string;
}

const NotificationBar: React.FC<NotificationBarProps> = ({ text, icon }) => {
  const { showNotificationBar, setShowNotificationBar } = useContext(GlobalContext);

  if (typeof localStorage !== "undefined") {
    const localStorageValue = localStorage.getItem("ShowNotificationBar");
    if (localStorageValue && !JSON.parse(localStorageValue)) {
      return null;
    }
  }

  const handleClick = () => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("ShowNotificationBar", JSON.stringify(false));
    }
    setShowNotificationBar(false);
  };

  return showNotificationBar ? (
    <div className="notification">
      <div className="notification__copy">
        <i className={icon}></i>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      <a className="notification__close" onClick={() => handleClick()}></a>
    </div>
  ) : null;
};

export default NotificationBar;
