import { graphql, PageProps } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import Seo from "../../components/seo";
import { TideItemForWebsite } from "../../types";
import { parse } from "../../utils/component";
import { QsmData } from "../../components/qsm";
import PlaceholderMessage from "../../components/placeholder-message";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";

export interface PageNotFoundData extends QsmData {
  website: TideItemForWebsite;
}
interface PageNotFoundContext {
  breadcrumbs: Breadcrumb[];
}

const PageNotFound: React.FC<PageProps<PageNotFoundData, PageNotFoundContext>> = ({ data, pageContext: { breadcrumbs } }) => {
  const { notificationBar } = useContext<ComponentContextType>(ComponentContext);
  return (
    <Layout>
      <Seo
        description="404 - Page not found"
        keywords="404 - Page not found"
        title="404 - Page not found"
        website={parse(data.website)}
        breadcrumbs={parse(breadcrumbs)}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
        />
      )}
      <Navbar website={parse(data.website)} />
      <PlaceholderMessage website={data.website.name} />
      <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
    </Layout>
  );
};

export default PageNotFound;

export const query = graphql`
  query PageNotFoundQuery($websiteId: String) {
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
