import React from "react";
import NewsletterSportreizenForm from "./sportreizen";
import NewsletterVoetbalreizenForm from "./voetbalreizen";

interface NewsletterFormProps {}

const NewsletterForm: React.FC<NewsletterFormProps> = () => {
  return (
    <>
      {process.env.WEBSITE === "Sportreizen" && <NewsletterSportreizenForm />}
      {process.env.WEBSITE === "Voetbalreizen" && <NewsletterVoetbalreizenForm />}
    </>
  );
};

export default NewsletterForm;
