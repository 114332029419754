import React from "react";
import { TideItemForNavigationItem, TideItemForNavigationSection } from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import { generateRootPath } from "../../utils/path";
import Link from "../link";

interface FooterLinkGroupProps {
  linkGroupNavigationSection?: TideItemForNavigationSection;
}

const FooterLinkGroup: React.FC<FooterLinkGroupProps> = ({ linkGroupNavigationSection }) => {
  if (!linkGroupNavigationSection?.childItems) {
    return null;
  }

  const links = getChildItemsWithTypename("TideItemForNavigationItem", linkGroupNavigationSection) as TideItemForNavigationItem[];

  return (
    <div className="footer__link-group">
      {links.map((item) => (
        <Link
          title={parse(item.content?.general?.title)}
          path={generateRootPath(parse(item.content?.general?.path))}
          url={parse(item.content?.general?.url)}
          key={item.id}
        >
          {item.content?.general?.title}
        </Link>
      ))}
    </div>
  );
};

export default FooterLinkGroup;
