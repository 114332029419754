import React from "react";
import Icon from "../icon";
import Link from "../link";
import translate from "../../utils/translate";
import translations from "./translations.json";

interface PlaceholderMessageProps {
  website?: string;
}

const PlaceholderMessage: React.FC<PlaceholderMessageProps> = ({ website }) => {
  return (
    <div className="placeholder-message">
      <div className="placeholder-message__container">
        <Icon name={website?.toLowerCase() === "voetbalreizen" ? "404-voetbalreizen" : "404"} />
        <h1 className="placeholder-message__heading">{translate(translations, "nl-BE", "PAGE_NOT_FOUND")}</h1>
        <p>
          {website?.toLowerCase() === "voetbalreizen"
            ? translate(translations, "nl-BE", "CUSTOM_MESSAGE_LINE1_VOETBALREIZEN")
            : translate(translations, "nl-BE", "CUSTOM_MESSAGE_LINE1")}
          <br />
          {website?.toLowerCase() === "voetbalreizen"
            ? translate(translations, "nl-BE", "CUSTOM_MESSAGE_LINE2_VOETBALREIZEN")
            : translate(translations, "nl-BE", "CUSTOM_MESSAGE_LINE2")}
        </p>
        <Link path="/" title={translate(translations, "nl-BE", "BACK_TO_HOME")} className="cta">
          {translate(translations, "nl-BE", "BACK_TO_HOME")}
        </Link>
      </div>
    </div>
  );
};

export default PlaceholderMessage;
