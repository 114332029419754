import { graphql } from "gatsby";
import React, { useContext } from "react";
import { Image } from "../../types";
import { getSpecificChildItemWithName } from "../../utils/component";
import Brand from "../brand";
import Icon from "../icon";
import Trust from "../trust";
import FooterContact from "./footer-contact";
import FooterNewsletter from "./footer-newsletter";
import FooterLinks from "./footer-links";
import FooterSocials from "./footer-socials";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";

interface FooterProps {
  name?: string;
  logo?: Image;
}

const Footer: React.FC<FooterProps> = ({ name, logo }) => {
  const { footerNavigation } = useContext<ComponentContextType>(ComponentContext);

  const contactNavigationSection = getSpecificChildItemWithName("Contact", footerNavigation);
  const newsLetterNavigationSection = getSpecificChildItemWithName("Nieuwsbrief", footerNavigation);
  const quickLinksNavigationSection = getSpecificChildItemWithName("Snelle links", footerNavigation);
  const menuNavigationSection = getSpecificChildItemWithName("Menu", footerNavigation);

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__top">
          <Brand name={name} logo={logo} />
          <Trust styling="footer__trust" />
        </div>

        <div className="footer__bottom">
          <div className="footer__column">
            <FooterContact contactNavigationSection={contactNavigationSection} />
            <FooterSocials />
          </div>
          <div className="footer__column">
            <FooterNewsletter newsletterNavigationSection={newsLetterNavigationSection} />
          </div>
          <div className="footer__column">
            <FooterLinks linksNavigationSection={quickLinksNavigationSection} />
          </div>
          <div className="footer__column">
            <FooterLinks linksNavigationSection={menuNavigationSection} />
          </div>
        </div>

        <div className="footer__legal">
          <div className="payment-logos">
            <div className="payment-logos__visa">
              <Icon name="visa" />
            </div>
            <div className="payment-logos__mastercard">
              <Icon name="mastercard" />
            </div>
            <div className="payment-logos__bancontact">
              <Icon name="bancontact" />
            </div>
            <div className="payment-logos__ideal">
              <Icon name="ideal" />
            </div>
            <div className="payment-logos__paypal">
              <Icon name="paypal" />
            </div>
          </div>

          {footerNavigation?.content?.general?.text && <small dangerouslySetInnerHTML={{ __html: footerNavigation.content.general.text }}></small>}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
